import type { ILogo } from "../media/logo";
import type { IArticleContent } from "./articleContent";
import type { IArticleImage } from "./articleImage";

export type IArticleAuthor = {
  name: string;
  id: number;
};

export interface IArticle {
  id: number;
  date_published: Date | string;
  authors?: IArticleAuthor[];
  image: number;
  url: string;
  lead: string;
  extract_date: string;
  images: IArticleImage[];
  summary?: string[];
  headline?: string;
  utm_url?: string;
  publisher?: {
    id: number;
    name: string;
    url: string;
    logos: ILogo[];
  };
  summaries: IArticleContent[];
  leads: IArticleContent[];
  quotes: IArticleContent[];
  headlines: IArticleContent[];
}

export const defaultArticle: IArticle = {
  id: 0,
  date_published: "",
  image: 0,
  url: "",
  lead: "",
  extract_date: "",
  images: [],
  summaries: [],
  leads: [],
  quotes: [],
  headlines: [],
};

export type AuthorType = {
  name: string;
  id: string;
};

export type ArticleEditFn = (changes: {
  articleId: number;
  headline?: string;
  summary?: string;
  author?: string;
  datePublished?: string;
}) => void;
