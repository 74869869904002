import {
  CreditCard,
  Gear,
  Lifebuoy,
  Lightbulb,
  MagnifyingGlass,
  SidebarSimple,
  SignOut,
} from "@phosphor-icons/react";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { HandraiseH, HandraiseLogoMark } from "@/assets/handraise";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/navigation/dropdown-menu";

import { useFeature } from "@/lib/feature-management";
import { useAppDispatch } from "@/lib/hooks/storeHooks";
import { cn } from "@/lib/utils";
import { setLeftSidebarVisible } from "@/store/actions/builder";
import useAuth from "@/store/authSlice";
import { useEffect, useRef, useState } from "react";
import { Button } from "../button";
import SidebarNavigation from "../dashboard/sidebarNavigation";
import { OwnerInitials } from "../ui/controls/ownerInfo";
import { Separator } from "../ui/controls/separator";
import { Avatar, AvatarFallback, AvatarImage } from "../ui/data-display/avatar";
import { Input } from "../ui/forms/input";

interface SidebarProps {
  collapsed?: boolean;
  className?: string;
}

const Sidebar = ({ collapsed = false, className }: SidebarProps) => {
  const [{ name, email, avatar }, employee] = useAuth((state) => [
    state.user,
    state.isEmployee,
  ]);
  const logoutUserClick = useAuth((state) => state.logoutUser);
  const hasHelpDocs = useFeature("has-help-docs", false);
  const canShareFeedback = useFeature("can-share-feedback", false);
  const hasBilling = useFeature("has-billing", false);
  const canSearchSidebar = useFeature("can-search-sidebar", false);
  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [isSearching, setIsSearching] = useState(false);

  const searchInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isSearching) {
      if (searchInputRef?.current) searchInputRef.current.focus();
    } else {
      setSearchTerm("");
    }
  }, [isSearching]);

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  const dispatch = useAppDispatch();

  return (
    <aside
      className={cn(
        "h-screen bg-slate-100 border-r border-slate-200 flex flex-col duration-500 pt-3 relative",
        collapsed && "w-14",
        className,
      )}
    >
      <div className="flex justify-between items-center">
        <Button
          variant="link"
          className={cn("p-0 mb-0", collapsed && "w-14")}
          onPress={() => {
            navigate("/newsfeeds");
          }}
        >
          {collapsed ? (
            <HandraiseH className="w-10 px-2.5" />
          ) : (
            <HandraiseLogoMark className="pt-2 px-5 w-36" />
          )}
        </Button>
        {!collapsed && (
          <Button
            onPress={() => dispatch(setLeftSidebarVisible(false))}
            variant="link"
            className={"absolute right-2 px-2 z-10 top-[25px] h-auto"}
          >
            <SidebarSimple />
          </Button>
        )}
        {(canSearchSidebar || employee) && (
          <Button
            variant="ghost"
            className="mr-1 mt-1.5 text-slate-400"
            onPress={() => setIsSearching((prev) => !prev)}
          >
            <MagnifyingGlass size={18} />
          </Button>
        )}
      </div>

      <div className={cn("h-0 transition-all", isSearching && "h-10 mt-3.5")}>
        {isSearching && (
          <Input
            value={searchTerm}
            ref={searchInputRef}
            className="border-none bg-slate-200 h-10 text-slate-900 px-1 text-sm w-full placeholder:text-slate-600"
            onChange={handleSearch}
            type="text"
            placeholder="Search feeds and campaigns"
          />
        )}
      </div>
      <nav className="p-2 pt-3.5 flex-1 text-sm flex flex-col gap-2 overflow-y-auto overflow-x-hidden custom-scrollbar">
        <SidebarNavigation collapsed={collapsed} filter={searchTerm} />
      </nav>
      <div
        className={cn(
          "p-3 pt-1.5 border-t-slate-200 flex flex-col text-sm flex-initial",
          !collapsed && "border-top-[1px]",
        )}
      >
        {hasHelpDocs && (
          <NavLink
            to="/settings"
            className="flex items-center px-2 py-1 h-8 rounded transition-colors "
          >
            <Lifebuoy size={16} />
            <span className="ml-2">Help Docs</span>
          </NavLink>
        )}
        {canShareFeedback && (
          <NavLink
            to="/settings"
            className="flex items-center px-2 py-1 h-8 rounded  transition-colors active:bg-slate-200"
          >
            <Lightbulb size={16} />
            <span className="ml-2">Share feedback</span>
          </NavLink>
        )}
        <Separator />
        <div className="flex flex-row gap-2 items-center py-1">
          <DropdownMenu>
            <DropdownMenuTrigger>
              <div className="flex flex-row p-2">
                <div className="pr-2 pt-1.5">
                  <Avatar
                    className={cn(
                      "ring-offset-1 ring-blue-750/40 ring-2",
                      collapsed && "mt-1 w-7 h-7",
                    )}
                  >
                    <AvatarImage
                      src={avatar ?? undefined}
                      alt="Your Avatar Image"
                    />
                    <AvatarFallback>
                      <OwnerInitials owner={name} name={name} email={email} />
                    </AvatarFallback>
                  </Avatar>
                </div>
                {!collapsed && (
                  <div className="flex flex-col">
                    <div className="text-left">{name || "User Name"}</div>
                    <div className="text-left text-slate-400 text-xs">
                      {email}
                    </div>
                  </div>
                )}
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuPortal>
              <DropdownMenuContent>
                {collapsed && (
                  <>
                    <div className="flex flex-col text-sm p-1 px-2">
                      <div>{name || "User Name"}</div>
                      <div className="text-slate-400 text-xs">{email}</div>
                    </div>
                    <DropdownMenuSeparator />
                  </>
                )}
                <DropdownMenuItem>
                  <NavLink to={"/settings"} className="flex items-center gap-2">
                    <Gear />
                    <span className="text-slate-900 text-sm">
                      Account Settings
                    </span>
                  </NavLink>
                </DropdownMenuItem>
                {hasBilling && (
                  <DropdownMenuItem>
                    <NavLink
                      to={"/settings"}
                      className="flex items-center gap-2"
                    >
                      <CreditCard />
                      <span className="text-slate-900 text-sm">Billing</span>
                    </NavLink>
                  </DropdownMenuItem>
                )}
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={logoutUserClick}
                  className="flex items-center gap-2"
                >
                  <SignOut className="text-rose-900" />
                  <span className="text-rose-900 text-sm">Log out</span>
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenuPortal>
          </DropdownMenu>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
