import { graphql } from "../graphql";
import { tierConfigurationFragment } from "./tier";

const FeedFragment = graphql(`
  fragment FeedFragment on FeedType @_unmask {
    id
    name
  }
`);

export const FeedOverviewQuery = graphql(
  `
  query FeedOverviewQuery {
    feeds {
      ...FeedFragment
      updatedAt
      jobLastRun
    }
  }
`,
  [FeedFragment],
);

export const SideBarDataQuery = graphql(
  `
    query SideBarDataQuery {
        feeds {
          ...FeedFragment
          terms {
            term
            description
          }
          knownTags {
            tag
          }
        }
        campaigns {
            id
            name
        }
    }
`,
  [FeedFragment],
);

export type SideBarQueryFeedType = ReturnType<
  Exclude<typeof SideBarDataQuery.__apiType, undefined>
>["feeds"];

export type SideBarQueryCampaignsType = ReturnType<
  Exclude<typeof SideBarDataQuery.__apiType, undefined>
>["campaigns"];

export const NewsFeedsDataQuery = graphql(`
query NewsFeedsDataQuery($dayFilter: Int) {
  newsfeedData(daysToFilter: $dayFilter) {
    id
    feedName
    jobLastRun
    feedItems {
      id
      articleId
      summary
      imageUrl
      headline
      maxScore
      maxSocial
      prominence
      storyArticleCount
      storyChartIds
      storyChartScores
      storyFirstArticlePublish
      itemType
      articleLastUpdateDate
      articleReadership
      maxDomainAuthority
      storyLastArticlePublish
      articleUrl
      articleNewsCategory
      storyPublishers {
        iconUrl
        name
      }
      articlePublisher {
        id
        name
        url
        logoUrl
      }
      articleMentions {
        snippet
        term
        location
      }
      storyPublishers {
        iconUrl
        name
      }
      discoveredTags {
        id
        tag
        tagKind
      }
      articleAuthors {
        id
        name
      }
    }
  }
}
`);

export const NewsFeedsSearchQuery = graphql(`
query NewsFeedSearchQuery($feedId: Int!, $searchTerm: String!, $selectedItems: [Int!]!) {
  feedSearch(
    input: {
      search: {
        feedId: $feedId,
        searchTerm: $searchTerm,
        selectedItems: $selectedItems,
        advancedSearch: true
      }
    }
  ) {
    term
    items {
      id
      field {
        name
        content
      }
    }
  }
}
`);

export type NewsFeedsSearchQueryType = ReturnType<
  Exclude<typeof NewsFeedsSearchQuery.__apiType, undefined>
>;

export const UpdateArticleMutation = graphql(`
  mutation UpdateArticleMutation($input: OverrideFeedArticleInput!) {
     overrideFeedArticle(
      input: $input
    ) {
      overrides
    }
  }
`);

export const NewsFeedV2Query = graphql(
  `
    query FeedQueryV2($feedId: ID!, $dayFilter: Int, $startDate: Date, $endDate: Date) {
      feedDataV2(filters: {daysToFilter: $dayFilter, feedId: $feedId, startDate: $startDate, endDate: $endDate}, pagination: null) {
        id
        articleId
        headline
        imageUrl
        maxScore
        articlePublisher {
          id
          logoUrl
          name
          url
        }
        articleAuthors {
          id
          name
        }
        articleNewsCategory
        articleReadership
        articleSentiment {
          polarity
          rationale
        }
        firstArticleMention {
          snippet
          term
          location
        }
        articleUrl
        datePublished
        feedId
        headline
        id
        imageUrl
        articleLastUpdateDate
        knownTags
        maxDomainAuthority
        maxScore
        prominence
        maxSocial
        sortDate
        summary
        topics
      }
      readershipConfiguration {
        ...TierConfig
        tenant {
          enableReadership
        }
      }
      tierConfiguration {
        ...TierConfig
        tenant {
          enableCustomTierScoring
        }
      }
      tier1Pub: publisherConfiguration(tier: TIER_ONE) {
        __typename
        publishers {
            id
          }
      }
      tier2Pub: publisherConfiguration(tier: TIER_TWO) {
        __typename
        publishers {
            id
          }
      }
      tier3Pub: publisherConfiguration(tier: TIER_THREE) {
        __typename
        publishers {
            id
          }
      }
    }
  `,
  [tierConfigurationFragment],
);

export type DomainOrReadershipConfig =
  | ReturnType<
      Exclude<typeof NewsFeedV2Query.__apiType, undefined>
    >["readershipConfiguration"]
  | ReturnType<
      Exclude<typeof NewsFeedV2Query.__apiType, undefined>
    >["tierConfiguration"];

export const DiscoveredTagQuery = graphql(
  `
  query DiscoveredTagQuery($feedId: Int!, $startDate: DateTime, $endDate: DateTime) {
    feedArticleTagsByFeed(
      feedId: $feedId
      startDate: $startDate
      endDate: $endDate
      tagKinds: ["events", "topics", "initiatives", "people", "themes"]
    ) {
      tagKind
      tag
      feedArticleIds
    }
  }
  `,
);

export const NewsFeedPublishers = graphql(`
  query NewsFeedPublishers($pubFilter: String = "", $pubLimit: Int = 50) {
    publishers(pagination: {limit: $pubLimit} filter: $pubFilter) {
      id
      name
      url
      logos {
        image {
          url
        }
      }
    }
}
`);

export type NewsFeedPublishersDataType = ReturnType<
  Exclude<typeof NewsFeedPublishers.__apiType, undefined>
>["publishers"];

export type NewsFeedPublisherType = NewsFeedPublishersDataType[number];

export type NewsFeedDataType = ReturnType<
  Exclude<typeof NewsFeedsDataQuery.__apiType, undefined>
>["newsfeedData"][number];

export type FeedType = ReturnType<
  Exclude<typeof NewsFeedV2Query.__apiType, undefined>
>["feedDataV2"][number];

export type ArticlePublisherType = FeedType["articlePublisher"];

export interface FeedWithAugTypes extends FeedType {
  searchHit?: {
    mention: string;
    term: string;
  };
  tierLevel?: string;
}

export const deleteFeedArticleMutation = graphql(`
mutation deleteFeedArticle($input: DeleteFeedArticleInput!) {
  deleteFeedArticle(input: $input) {
    message
  }
}
`);

export type DeleteExecuteType = Exclude<
  typeof deleteFeedArticleMutation.__apiType,
  undefined
>;

export type DeleteFeedArticleType =
  ReturnType<DeleteExecuteType>["deleteFeedArticle"];

export type DeleteFeedArticleParemeters =
  Parameters<DeleteExecuteType>[0]["input"];

export type FeedDataTermsType = {
  term: string;
  description: string;
};

export const FEED_MUTATION = graphql(`
    mutation FeedMutation($data: FeedUpdateInput!, $feedId: Int!) {
      updateFeed(data: $data, id: $feedId) {
        ... on FeedType {
          name
          terms {
            id
            term
            description
          }
          knownTags {
            id
            tag
          }
        }
      }
    }
  `);

export const CREATE_FEED_MUTATION = graphql(`
    mutation CreateFeedMutation($data: FeedCreateInput!) {
      createFeed(data: $data) {
        ... on FeedType {
          id
          name
          terms {
            term
            description
            id
          }
          knownTags {
            id
            tag
          }
        }
      }
    }
  `);
