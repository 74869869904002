import { isDevelopment } from "@/config";
import type {
  FeedDataTermsType,
  FeedWithAugTypes,
} from "@/data-access/news/newsFeeds";
import { derive } from "derive-zustand";
import { create, useStore } from "zustand";
import { devtools } from "zustand/middleware";
import {
  type DerivedFilterItem,
  type FeedSlice,
  createFeedSlice,
} from "./feedSlice";

const useFeedStore = create<FeedSlice>()(
  devtools((...a) => ({ ...createFeedSlice(...a) }), {
    name: "feedStore",
    enabled: isDevelopment,
    store: "feed",
  }),
);

export default useFeedStore;

// const deriveStoryCount = derive<number>((get) => {
//   const { feedItems } = get(useFeedStore);
//   return feedItems.filter((item) => item.itemType === "STORY").length;
// });

const deriveArticleCount = derive<number>((get) => {
  const { feedItems, removalArr } = get(useFeedStore);
  return feedItems.length - removalArr.length;
});

const deriveFilterOrSearch = derive<boolean>((get) => {
  const { categoryAppliedFilters, searchTerms } = get(useFeedStore);
  return (
    (categoryAppliedFilters
      ? Object.values(categoryAppliedFilters).some(
          (filter) => (filter?.selected?.length ?? 0) > 0,
        )
      : false) || searchTerms.length > 0
  );
});

const deriveFilteredFeedItems = derive<Partial<FeedWithAugTypes>[]>((get) => {
  const { filterFeedIdSearch, feedItems, removalArr, sortOrder } =
    get(useFeedStore);

  const isFilteredOrSearched = get(deriveFilterOrSearch);

  return (
    isFilteredOrSearched
      ? (filterFeedIdSearch
          .filter(([id]) => !removalArr.includes(id))
          .map(([id, searchHit]) => {
            const item = feedItems.find((fi) => fi.id === id);
            return {
              ...(item ?? {}),
              searchHit,
            };
          }) satisfies Partial<FeedWithAugTypes>[])
      : ((feedItems?.filter((item) => !removalArr.includes(item.id ?? -1)) ??
          []) satisfies Partial<FeedWithAugTypes>[])
  ).sort((a, b) => {
    if (sortOrder === "SCORE_DESC") {
      return (b.maxScore || 0) - (a.maxScore || 0);
    }
    if (sortOrder === "SCORE_ASC") {
      return (a.maxScore || 0) - (b.maxScore || 0);
    }
    if (sortOrder === "DATE_DESC" || sortOrder === "DATE_ASC") {
      const dateA = a.articleLastUpdateDate
        ? new Date(a.articleLastUpdateDate)
        : new Date(0);
      const dateB = b.articleLastUpdateDate
        ? new Date(b.articleLastUpdateDate)
        : new Date(0);
      if (sortOrder === "DATE_DESC") {
        return dateB.getTime() - dateA.getTime();
      }
      return dateA.getTime() - dateB.getTime();
    }
    return 0;
  });
});

const deriveFilterGroups = derive<
  {
    key: string;
    title: string;
    filters: DerivedFilterItem[];
    sorted: boolean;
  }[]
>((get) => {
  const { categoryAppliedFilters, removalArr, filterFeedIdSearch } =
    get(useFeedStore);
  if (!categoryAppliedFilters) return [];
  const filterIds = filterFeedIdSearch.map(([id]) => id);

  return Object.entries(categoryAppliedFilters).map(
    ([categoryKey, categoryValue]) => {
      let filters = Object.entries(categoryValue?.itemCounts ?? {}).map(
        ([filter, count]) => ({
          id: categoryKey + filter,
          value: filter,
          totalCount: count.length,
          count: count.filter(
            (id) => !removalArr.includes(id) && filterIds.includes(id),
          ).length,
          selected: categoryValue.selected?.includes(filter) ?? false,
        }),
      );

      // Sort the Social Engagement filters specifically
      if (categoryKey === "Social Engagement") {
        filters = filters.sort((a, b) => {
          if (a?.value === "0") return 1;
          if (b?.value === "0") return -1;
          const aValue = parseInt(
            a?.value?.split("-")[0]?.replace(",", "") ?? "0",
          );
          const bValue = parseInt(
            b?.value?.split("-")[0]?.replace(",", "") ?? "0",
          );
          return bValue - aValue;
        });
      }

      return {
        key: categoryKey,
        title: categoryKey,
        filters,
        sorted: categoryValue.sorted ?? true,
      };
    },
  );
});

// derive feedName
const deriveFeedName = derive<string>((get) => {
  const { feedId, feedSidebarData } = get(useFeedStore);
  const currentFeed = feedSidebarData.find((f) => +f?.id === feedId);
  return currentFeed ? currentFeed.name ?? "" : "";
});

// derive feedTerms
const deriveFeedTerms = derive<FeedDataTermsType[]>((get) => {
  const { feedId, feedSidebarData } = get(useFeedStore);
  const currentFeed = feedSidebarData.find((f) => +f?.id === feedId);
  return currentFeed ? currentFeed.terms ?? [] : [];
});

// derive knownTags
const deriveKnownTags = derive<string[]>((get) => {
  const { feedId, feedSidebarData } = get(useFeedStore);
  const currentFeed = feedSidebarData.find((f) => +f?.id === feedId);
  return currentFeed ? currentFeed.knownTags?.map((tag) => tag.tag) ?? [] : [];
});

const deriveFeedPercent = derive<number | null>((get) => {
  const { feedItems, filterFeedIdSearch, removalArr } = get(useFeedStore);
  if (!feedItems) return null;
  const totalStories = feedItems.length - removalArr.length;
  const filteredStoryCount = filterFeedIdSearch.filter(
    ([id]) => !removalArr.includes(id),
  ).length;
  return Math.round((filteredStoryCount / totalStories) * 100);
});

const useIsFilterOrSearch = () => {
  return useStore(deriveFilterOrSearch);
};

const useFeedPercent = () => {
  return useStore(deriveFeedPercent);
};

// Define hooks for each derived state
const useFeedName = () => {
  return useStore(deriveFeedName);
};

const useFeedTerms = () => {
  return useStore(deriveFeedTerms);
};

const useKnownTags = () => {
  return useStore(deriveKnownTags);
};

// const useStoryCount = () => {
//   return useStore(deriveStoryCount);
// };

const useArticleCount = () => {
  return useStore(deriveArticleCount);
};

const useFilteredFeedItems = () => {
  return useStore(deriveFilteredFeedItems);
};

const useFilterGroups = () => {
  return useStore(deriveFilterGroups);
};

// Export hooks

export {
  useIsFilterOrSearch,
  useFeedPercent,
  useFeedName,
  useFeedTerms,
  useKnownTags,
  // useStoryCount,
  useArticleCount,
  useFilteredFeedItems,
  useFilterGroups,
};
