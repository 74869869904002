import { type VariantProps, cva } from "class-variance-authority";
import {
  Button as AriaButton,
  type ButtonProps as AriaButtonProps,
  composeRenderProps,
} from "react-aria-components";

import { cn } from "@/lib/utils";
import { CaretUpDown } from "@phosphor-icons/react";
import React from "react";

const buttonVariants = cva(
  [
    "inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors transition-duration-100",
    /* Disabled */
    "data-[disabled]:pointer-events-none data-[disabled]:opacity-50 ",
    /* Focus Visible */
    "data-[focus-visible]:outline-none data-[focus-visible]:ring-2 data-[focus-visible]:ring-ring data-[focus-visible]:ring-offset-2 data-[focus-visible]:ring-blue-750",
    /* Resets */
    "!focus-visible:outline-none focus:outline-none",
  ],
  {
    variants: {
      variant: {
        input: "rounded-md bg-slate-400/15 text-slate-500",
        default:
          "bg-violet-800 text-white data-[hovered]:bg-violet-700 data-[pressed]:bg-violet-900",
        destructive:
          "bg-destructive text-destructive-foreground data-[hovered]:bg-destructive/90",
        outline:
          "border border-border-default bg-background hover:bg-slate-400/5 active:bg-slate-500/10 shadow text-slate-800",
        secondary: "bg-slate-100 text-slate-700",
        ghost:
          "data-[hovered]:bg-accent data-[hovered]:text-accent-foreground text-slate-700 tracking-tight",
        link: "text-primary underline-offset-4 data-[hovered]:underline",
      },
      size: {
        default: "h-10 px-4 py-2",
        input:
          "py-1 px-2 absolute h-6 top-1/2 -translate-y-1/2 absolute right-2.5 text-xs",
        sm: "h-9 rounded-md px-3",
        lg: "h-11 rounded-md px-8",
        icon: "size-9",
        xsIcon: "size-5",
        none: "",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  },
);

interface ButtonProps
  extends AriaButtonProps,
    VariantProps<typeof buttonVariants> {}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, ...props }, ref) => {
    return (
      <AriaButton
        ref={ref}
        className={composeRenderProps(className, (className) =>
          cn(
            buttonVariants({
              variant,
              size,
              className,
            }),
          ),
        )}
        {...props}
      />
    );
  },
);

interface DropdownButtonProps extends ButtonProps {
  ariaLabel: string;
  icon?: React.ReactNode;
  label: string;
  customLabel?: string;
}

const DropdownButton = React.forwardRef<HTMLButtonElement, DropdownButtonProps>(
  ({ ariaLabel, icon, label, customLabel, ...props }, ref) => (
    <Button
      ref={ref}
      aria-label={ariaLabel}
      size="sm"
      variant="outline"
      className="px-1 text-slate-500"
      {...props}
    >
      {icon}
      <span className="mr-1" />
      <span className="text-slate-600">{customLabel || label}</span>
      <CaretUpDown className="ml-1 h-5 w-5 shrink-0" />
    </Button>
  ),
);

export { Button, DropdownButton, buttonVariants };
export type { ButtonProps };
